<template>
  <div>
    <!-- 上部分 -->
    <div class="top">
      <div class="meter">
        <img src="@/assets/distributionImg/dots.png" alt="" />
        <p>经营店铺数量</p>
      </div>

      <!-- 上面三个 -->
      <div class="sphere">
        <div
          class="progress"
          v-for="(item, index) in listShopData"
          :key="index"
        >
          <el-progress
            class="progresssty"
            type="circle"
            :percentage="25"
            :color="item.color"
            define-back-color="#2d3e50"
            stroke-width="6"
            width="80"
            text-color="#ffff"
          ></el-progress>
          <div class="ellipse">
            <div class="ellipse-left" style="background: #1ec6a8">
              {{ item.value }}家
            </div>
            <div class="ellipse-right">{{ item.name }}</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 下部分 -->
    <div class="botom">
      <div class="meter">
        <img src="@/assets/distributionImg/dots.png" alt="" />
        <p>农资交易量</p>
      </div>

      <div class="productType">
        <button :class="check == 1 ? 'check' : ''" @click="choice(1, '')">
          累计
        </button>
        <button :class="check == 2 ? 'check' : ''" @click="choice(2, 'year')">
          本年
        </button>
        <button :class="check == 3 ? 'check' : ''" @click="choice(3, 'month')">
          本月
        </button>
      </div>

      <div id="myChart"></div>
      <div class="kindType">
        <p>{{ typeName }}类</p>
        <p>数量</p>
        <p>{{ num }}</p>
      </div>
      <div class="detailed">
        <div
          class="individual"
          v-for="(item, index) in individualData"
          :key="index"
        >
          <p>{{ item.species3 }}</p>
          <p>{{ item.quantity }}</p>
        </div>
      </div>
      <img
        src="@/assets/distributionImg/left.png"
        class="arrow left"
        @click="clickLeft"
      />
      <img
        src="@/assets/distributionImg/right.png"
        class="arrow right"
        @click="clickRight"
      />
    </div>
  </div>
</template>

<script>
import {
  listShop,
  purchaseCountB,
  purchaseCountC,
  getPurchaseType,
  getTypeCount,
} from "@/api/seedlingApi/seedling.js";

export default {
  name: "Left",
  components: {
    // [Button.name]: Button // 此处是引入组件名字的方法
  },
  data() {
    return {
      seriesdata1: "",
      listShopData: [],
      pageNum: 1,
      individualData: [],
      typeName: "",
      num: 0,
      check: 1,
      params: {
        status: 5,
        areaCode: JSON.parse(sessionStorage.getItem("INFO")).areaCode,
      },
      secondParamd: {},
    };
  },
  created() {
    this.getlistShop();
    this.getpurchaseCountB();
  },
  mounted() {},
  methods: {
    getlistShop() {
      listShop().then((res) => {
        const color = [
          "#66DE69",
          "#CBD26D",
          "#0AE3F4",
          "#1483FF",
          "#a814ff",
          "#41CBAB",
          "#7BDD43",
        ];
        this.listShopData = Object.entries(res.data).map(
          ([name, value], index) => ({
            name,
            value,
            color: color[index % color.length],
          })
        );
      });
    },
    getpurchaseCountC(val) {
      this.typeName = val;
      if (this.check == 1) {
        var secondParams = {
          species: val,
          status: 5,
          areaCode: JSON.parse(sessionStorage.getItem("INFO")).areaCode,
        };
      } else if (this.check == 2) {
        var secondParams = {
          species: val,
          status: 5,
          areaCode: JSON.parse(sessionStorage.getItem("INFO")).areaCode,
          dateFlag: "year",
        };
      } else {
        var secondParams = {
          species: val,
          status: 5,
          areaCode: JSON.parse(sessionStorage.getItem("INFO")).areaCode,
          dateFlag: "month",
        };
      }
      getTypeCount(secondParams).then((res) => {
        this.individualData=res.data
        this.num = this.individualData.reduce((acc, cur) => acc + cur.quantity, 0);
      });
    },
    getpurchaseCountB() {
      getPurchaseType(this.params).then((res) => {
        const colorList = [
          "#66DE69",
          "#CBD26D",
          "#0AE3F4",
          "#1483FF",
          "#a814ff",
          "#41CBAB",
          "#7BDD43",
          "#FFC653",
          "#FF6519",
          "#EE3939",
          "#FFAFDA",
          "#00FFFF",
        ];

        this.seriesdata1 = res.data.map((item, index) => ({
          name: item.species,
          value: item.quantity,
          itemStyle: {
            normal: {
              color: colorList[index],
            },
          },
        }));
        this.getEcharts();
        this.typeName = this.seriesdata1[0].name;
        this.getpurchaseCountC(this.typeName);
      });
    },
    getEcharts() {
      const colorList = [
        "#66DE69",
        "#CBD26D",
        "#0AE3F4",
        "#1483FF",
        "#a814ff",
        "#41CBAB",
        "#7BDD43",
        "#FFC653",
        "#FF6519",
        "#EE3939",
        "#FFAFDA",
        "#00FFFF",
      ];

      const myChart = this.$echarts.init(document.getElementById("myChart"));
      myChart.setOption(
        {
          backgroundColor: "transparent",
          grid: {
            top: "10%",
            left: "20%",
            right: "20%",
            bottom: "40%",
            borderWidth: 1,
            borderColor: "#f0f0f0",
          },

          series: {
            type: "pie",
            center: ["50%", "50%"],
            radius: ["35%", "50%"],
            clockwise: true,
            //startAngle: 50,
            avoidLabelOverlap: true,
            hoverOffset: 15,
            itemStyle: {
              normal: {
                borderWidth: 5,
                borderColor: "#fff",
                boderRadius: 10,
                color: function (params) {
                  return colorList[params.dataIndex];
                },
              },
            },
            label: {
              show: true,
              position: "outside",
              formatter: function (data) {
                //console.log('data',data)
                return `${data.name}:${data.value}种`;
              },
              rich: {
                name: {
                  fontSize: 12,
                  color: "#999",
                  fontWeight: "Bold",
                },
                value: {
                  fontSize: 12,
                  color: "#999",
                  align: "left",
                },
              },
            },
            labelLine: {
              show: false,
              normal: {
                length: 5,
                length2: 30,
                align: "right",
                lineStyle: {
                  width: 1,
                },
              },
            },
            data: this.seriesdata1,
            seriesIndex: 0,
          },
        },
        true
      );
      const that = this;
      myChart.on("click", function (params) {
        that.getpurchaseCountC(params.name);
      });
    },
    clickLeft() {
      if (this.pageNum > 1) {
        this.pageNum = this.pageNum - 1;
        this.getpurchaseCountC(this.typeName);
      }
    },
    clickRight() {
      this.pageNum = this.pageNum + 1;
      this.getpurchaseCountC(this.typeName);
    },
    choice(index, value) {
      this.check = index;
      if (index != 1) {
        this.params.dateFlag = value;
      } else {
        this.params = {
          status: 5,
          areaCode: JSON.parse(sessionStorage.getItem("INFO")).areaCode,
        };
      }
      this.getpurchaseCountB();
      this.getpurchaseCountC();
    },
  },
};
</script>

<style scoped lang="scss">
@function companyW($num) {
  @return ($num * 100)/1920;
}
@function companyH($num) {
  @return ($num * 100)/1080;
}
.top {
  width: companyW(444vw);
  height: companyH(465vh);
  background-image: linear-gradient(to bottom, #161225, #090b0f);

  .sphere {
    display: flex;
    justify-content: space-around;
    margin-top: companyH(60vh);
    flex-wrap: wrap;
  }

  .progress {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: companyH(20vh);
    .progresssty {
      border: companyW(6vw) solid #1b1e24;
      border-radius: 100%;
    }
    .ellipse {
      display: flex;
      color: #ffffff;
      font-size: companyW(14vw);
      margin-top: companyH(20vh);
      .ellipse-left {
        width: companyW(50vw);
        height: companyH(24vh);
        border-radius: companyH(24vh) 0 0 companyH(24vh);
        background-color: #1ec6a8;
        text-align: center;
        line-height: companyH(24vh);
      }

      .ellipse-right {
        width: companyW(50vw);
        height: companyH(24vh);
        border-radius: 0 companyH(24vh) companyH(24vh) 0;
        background-color: #263645;
        text-align: center;
        line-height: companyH(24vh);
      }
    }
  }
}
.meter {
  display: flex;
  img {
    margin: 0 companyW(10vw);
  }
  p {
    font-family: SourceHanSansCN, SourceHanSansCN;
    font-weight: bold;
    font-size: companyW(20vw);
    color: #ffffff;
  }
}
.botom {
  width: companyW(444vw);
  height: companyH(465vh);
  background-image: linear-gradient(to bottom, #161225, #090b0f);
  margin-top: companyH(22vh);
  position: relative;
}
#myChart {
  width: companyW(420vw);
  height: companyH(280vh);
  margin: 0 auto;
}
.kindType {
  color: #2cb252;
  display: flex;
  align-items: center;
  p:nth-child(1) {
    margin: 0 companyW(200vw) 0 companyW(50vw);
  }
  p:nth-child(2) {
    margin-right: companyW(20vw);
    color: #91a4ad;
    font-size: companyW(16vw);
  }
}
.detailed {
  padding: 0 companyW(15vw) 0 companyW(48vw);
  display: flex;
  flex-wrap: wrap;
  margin-top: companyH(25vh);

  .individual {
    color: #fff;
    margin-bottom: companyH(15vh);
    margin-right: companyW(33vw);
    p:nth-child(1) {
      color: #82929b;
      margin-bottom: companyH(8vh);
      font-size: companyW(14vw);
    }
  }
}
.arrow {
  width: companyW(32vw);
  height: companyH(32vh);
}
.left {
  position: absolute;
  left: companyW(10vw);
  bottom: companyH(58vh);
}
.right {
  position: absolute;
  right: companyW(10vw);
  bottom: companyH(58vh);
}
.productType {
  width: companyW(400vw);
  height: companyH(30vh);
  margin-left: companyW(180vw);

  button {
    background: #21163c;
    color: #a0a0a2;
    width: companyW(80vw);
  }
  .check {
    background: #4b2b89;
    color: #fff;
  }
}
</style>
