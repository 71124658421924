<template>
    <div id="yzMap"></div>
  </template>
  <script>
  import MapInit from '@/views/njall/components/TMap/MapInit.js';
  import { getdevice } from '@/api/njApi/njzl.js';
  export default {
    props: {},
    data () {
      return {
        zoom: 12,
        map: null,
        pointArray: [],
  
        timer: null
      };
    },
    mounted () {
      this.startTimer();
      // this.roadMap();
    },
    created () {
      this.getdeviceInfo();
    },
    watch: {
      pointArray: {
        handler (newDate, old) {
          if (newDate.length) {
            this.roadMap();
          } else {
            // this.map.destroy();
  
            this.roadMap();
          }
        },
        deep: true
      }
    },
    beforeDestroy () {
      // 在组件销毁前清除定时器，防止内存泄漏
      this.stopTimer();
    },
    methods: {
      async getdeviceInfo () {
        const data = await getdevice();
        if (data.code === '1') {
          this.pointArray = data.data.filter(item => {
            return item !== null;
          });
          console.log(this.pointArray, 'this.pointArray');
        }
      },
      roadMap () {
        MapInit.init()
          .then(T => {
  
  
  
            this.T = T;
            const imageURL =
              'http://t0.tianditu.gov.cn/img_w/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=img&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}&tk=c9cd7951db1ea5b311aae41f5c0d60e4';
            const lay = new T.TileLayer(imageURL, { minZoom: 1, maxZoom: 18 });
  
            const config = { layers: [ lay ] };
            this.map = new T.Map('yzMap', config);
            this.map.addControl(window.TMAP_SATELLITE_MAP);
  
            const storedObject = JSON.parse(localStorage.getItem('key'));
            this.map.centerAndZoom(
              new T.LngLat(storedObject.longitude, storedObject.latitude),
              14
            );
            this.map.addEventListener('click', this.MapClick);
            // 添加标注
  
            const marker = new T.Marker(
              new T.LngLat(storedObject.longitude, storedObject.latitude)
            );
  
            this.map.addOverLay(marker);
  
            var infoWin1 = new T.InfoWindow();
            var sContent = `<div> 名称:${ storedObject.name }</div>`;
            infoWin1.setContent(sContent);
            marker.addEventListener('click', function () {
              marker.openInfoWindow(infoWin1);
            });
  
            // 将标注添加到地图中
            for (var i = 0; i < this.pointArray.length; i++) {
              console.log(this.pointArray[i], '1111111111');
              // 添加标注
              let icon = new T.Icon({
                iconUrl:
                  'https://nsfwzx1.oss-cn-hangzhou.aliyuncs.com/202312211524nj_blue.png', //请求图标图片的URL
                iconSize: new T.Point(30, 30), //图标可视区域的大小。
                iconAnchor: new T.Point(30, 30) //图标的定位锚点
              });
  
              const marker = new T.Marker(
                new T.LngLat(
                  this.pointArray[i].lng || this.pointArray[i].longitude,
                  this.pointArray[i].lat || this.pointArray[i].latitude
                ),
                {
                  icon: icon
                }
              )
  
              ;(function () {
                let description =
                  this.pointArray[i].name || this.pointArray[i].terminalNo;
                var infoWin2 = new T.InfoWindow();
                var sContent1 = `<div>名称: ${ description }</div>`;
                infoWin2.setContent(sContent1);
  
                marker.addEventListener('click', function () {
                  marker.openInfoWindow(infoWin2);
                });
              }.call(this));
  
              this.map.addOverLay(marker);
            }
          })
          .catch();
      },
      MapClick () {
        this.$listeners.btn(true, 'map');
      },
  
      startTimer () {
        // 启动定时器，每三分钟获取一次数据
        this.timer = setInterval(() => {
  
          this.getdeviceInfo();
        }, 3 * 60 * 1000); // 3分钟，单位是毫秒
      },
      stopTimer () {
        // 清除定时器，防止内存泄漏
        clearInterval(this.timer);
      }
    }
  };
  </script>
  
  <style lang="scss" scoped>
  @function companyW($num) {
    @return ($num * 100)/1920;
  }
  @function companyH($num) {
    @return ($num * 100)/1080;
  }
  #yzMap {
    width: companyW(970vw);
    height: 81%;
  }
  </style>
  