<template>
  <div class="dyr-service">
    <Title
      class="dyr-service-title"
      njTitle="dingbu"
      typeOf="distributionImg"
      :title="`${address}农资配送`"
    ></Title>

    <div class="ContentBox">
      <Left></Left>
      <!-- 中间部分 -->
      <div class="ContentMiddle">
        <MidBox
          v-for="v in MidData"
          :key="v.id"
          :boxData="v"
          style="margin: 0 companyW(8vw)"
          @changeMaskd="changeMas"
        ></MidBox>
        <TMap class="tMap" v-on="$listeners" v-bind="$attrs"></TMap>
      </div>
      <div class="ContentRight">
        <!-- 右上 -->
        <div class="right-top">
          <div class="meter">
            <img src="@/assets/distributionImg/dots.png" alt="" />
            <p>产品种类量</p>
          </div>
          <div class="productType">
            <button
              :class="check == index ? 'check' : ''"
              @click="choice(index, item.species)"
              v-for="(item, index) in purshItems"
              :key="index"
            >
              {{ item.species }}
            </button>
          </div>
          <div class="maintiao" v-for="(item, index) in trendData" :key="index">
            <div class="trend">
              <p>{{ item.purchaseName }}</p>
              <p
                :class="
                  item.isRise == '上升'
                    ? 'shangsheng'
                    : item.isRise == '下降'
                    ? 'xiajiang'
                    : 'bubian'
                "
              >
                {{
                  item.isRise == "上升"
                    ? "同比上升" + ":" + item.number
                    : item.isRise == "下降"
                    ? "同比下降" + ":" + item.number
                    : "同比不变" + ":" + item.number
                }}
              </p>
              <img
                src="@/assets/distributionImg/jiantou.png"
                v-if="item.isRise == '上升'"
              />
              <img
                src="@/assets/distributionImg/xiajiang.png"
                v-if="item.isRise == '下降'"
              />

              <img
                src="@/assets/distributionImg/bubian.png"
                v-if="item.isRise == '不变'"
              />

              <p class="number">{{ item.thisMonthQuantity }}</p>
            </div>
            <el-progress :percentage="item.Bibliography"></el-progress>
          </div>

          <!-- <div class="tion">
            <div class="transaction">
              <p>{{ transaction[1].quantity }}</p>
              <p>种子交易量</p>
            </div>
            <div class="transaction">
              <p>{{ transaction[0].quantity }}</p>
              <p>农药交易量</p>
            </div>
          </div>
          <img src="@/assets/distributionImg/dizuo.png" class="dizuo" />
          <div class="tion tion_botom">
            <div class="transaction">
              <p>70</p>
              <p>化肥交易量</p>
            </div>
            <div class="transaction">
              <p>70</p>
              <p>兽药交易量</p>
            </div>
            <div class="transaction">
              <p>70</p>
              <p>饲料交易量</p>
            </div>
          </div>
          <img src="@/assets/distributionImg/dizuo.png" class="dizuo" /> -->
        </div>
        <!-- 右下 -->
        <div class="right-botom">
          <div class="meter">
            <img src="@/assets/distributionImg/dots.png" alt="" />
            <p>农资订单</p>
          </div>
          <div class="header">
            <p>分类</p>
            <p>经营者名称</p>
            <p>商品及数量</p>
            <p>销售时间</p>
          </div>

          <vueSeamlessScroll
            :data="orderData"
            class="seamless-warp"
            :class-option="classOption"
          >
            <div>
              <div
                class="xiangxi"
                v-for="(item, index) in orderData"
                :key="index"
              >
                <img src="@/assets/distributionImg/zhong.png" />
                <p class="shopName">{{ item.shopName }}</p>
                <p class="count">
                  {{ item.orderDetailList[0].purchaseName }}
                </p>
                <p>{{
                    item.orderDetailList[0].count
                  }}</p>
                <p class="orderTime">{{ item.orderTime }}</p>
              </div>
            </div>
          </vueSeamlessScroll>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Title from './components/title.vue';
import Left from './components/left.vue';
// 中间每个小标题
import MidBox from '@/views/distribution/components/MiddleBox.vue';
import TMap from '@/views/distribution/components/njzlMap.vue';
import { getIndexDetail } from '@/api/njApi/njzl.js';
import {
  selectAll,
  totalOrderAmount,
  getShopAndPurchase,
  getVolumeOfBusiness,
  getCountAndTongBi,
  getPurchaseType,
} from "@/api/seedlingApi/seedling.js";
import vueSeamlessScroll from "vue-seamless-scroll";

export default {
  components: {
    Title,
    Left,
    MidBox,
    TMap,
    vueSeamlessScroll,
  },
  created() {
    this.address = localStorage.getItem('address');
    // this.getIndexData();
    this.getselectAll();
    this.pursh();
  },
  data() {
    return {
      address: '',
      MidData: [
        {
          id: 1,
          title: '店铺',
          data: '',
          unit: '家',
        },
        {
          id: 2,
          title: '农资种类',
          data: '',
          unit: '种',
        },
        {
          id: 3,
          title: '农资订单',
          data: '',
          unit: '单',
        },
        {
          id: 4,
          title: '订单金额',
          data: '',
          unit: '元',
        },
      ],
      orderData: [],
      transaction: [],
      check: 0,
      purshItems: "",
      trendData: "",
    };
  },
  //监听属性 类似于data概念
  computed: {
    classOption() {
      return {
        step: 1, // 数值越大速度滚动越快
        limitMoveNum: 4, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      };
    },
  },
  methods: {
    // getIndexData() {
    //   getIndexDetail().then((res) => {

    //     this.MidData[0].data = res.data.carCount;
    //     this.MidData[1].data = res.data.allPower;
    //   });

    //   console.log(this.MidData, 1232224);
    // },
    getselectAll() {
      // 分页查询所有农资订单
      selectAll({
        pageNum: 1,
        pageSize: 10,
        areaCode: JSON.parse(sessionStorage.getItem('INFO')).areaCode,
        status: '5',
      }).then(res => {
        this.orderData = res.data.records;
        this.MidData[2].data = res.data.total;

        this.orderData.forEach(item => {
          item.orderTime =
            `${ item.orderTime.substring(5, 7)
            }-${
              item.orderTime.substring(8, 10)
            } ${
              item.orderTime.substring(11, 13)
            }:${
              item.orderTime.substring(14, 16) }`;
        });
      });
      // 统计农资订单总金额
      totalOrderAmount({
        areaCode: JSON.parse(sessionStorage.getItem('INFO')).areaCode,
        status: '5',
      }).then(res => {
        this.MidData[3].data = res.data;
      });
      // 获取农资实时交易量
      getVolumeOfBusiness({
        areaCode: JSON.parse(sessionStorage.getItem('INFO')).areaCode,
        status: '5',
      }).then(res => {
        if (res.code == 1) {
          this.transaction = res.data;
        } else {
          this.transaction = [
            {
              quantity: 0,
            },
            {
              quantity: 0,
            },
            {
              quantity: 0,
            },
            {
              quantity: 0,
            },
            {
              quantity: 0,
            },
          ];
        }
      });
      //查询店铺总数和农资种类总量
      getShopAndPurchase().then(res => {
        this.MidData[0].data = res.data.shopCount;
        this.MidData[1].data = res.data.purchaseCount;
      });
    },
    choice(index, species) {
      this.check = index;
      this.getTongData(species);
    },
    pursh() {
      getPurchaseType({
        status: 5,
        areaCode: JSON.parse(sessionStorage.getItem("INFO")).areaCode,
      }).then((res) => {
        this.purshItems = res.data;
        this.getTongData(this.purshItems[0].species);
      });
    },
    getTongData(species) {
      getCountAndTongBi({
        status: 5,
        areaCode: JSON.parse(sessionStorage.getItem("INFO")).areaCode,
        species: species,
      }).then((res) => {
        this.trendData = res.data;
        var sum = this.trendData.reduce((accumulator, currentValue) => {
          return accumulator + currentValue.thisMonthQuantity;
        }, 0);
        this.trendData.forEach((item) => {
          item.Bibliography = Math.round((item.thisMonthQuantity / sum) * 100);
          if (item.thisMonthQuantity > item.lastMonthQuantity) {
            item.isRise = "上升";
            if (item.lastMonthQuantity == 0) {
              item.number =
                Math.round(
                  (item.thisMonthQuantity - item.lastMonthQuantity) * 100
                ) + "%";
            } else {
              item.number =
                Math.round(
                  ((item.thisMonthQuantity - item.lastMonthQuantity) /
                    item.lastMonthQuantity) *
                    100
                ) + "%";
            }
          } else if (item.thisMonthQuantity == item.lastMonthQuantity) {
            item.isRise = "不变";
            item.number = "0%";
          } else {
            item.isRise = "下降";
            item.number =
              Math.round(
                -(
                  (item.thisMonthQuantity - item.lastMonthQuantity) /
                  item.lastMonthQuantity
                ) * 100
              ) + "%";
          }
        });
      });
    },
  },
};
</script>

  <style lang="scss" scoped>
@function companyW($num) {
  @return ($num * 100)/1920;
}
@function companyH($num) {
  @return ($num * 100)/1080;
}
.dyr-service {
  position: relative;
  width: companyW(1920vw);
  height: companyH(1080vh);
  box-sizing: border-box;
  // background-image: url(../../assets/drying-page/bcg-img.png);
  background-color: rgba(8, 27, 25);
  background-size: cover;
  background-repeat: no-repeat;

  .dyr-service-title {
    padding-top: companyH(8vh);
    width: companyW(1820vw);
    height: companyH(70vh);
    margin: 0 auto;
    background-size: contain;
    background-repeat: no-repeat;
  }
}
.ContentBox {
  max-width: companyW(1880vw);
  display: flex;
  margin: 0 auto;
  justify-content: space-between;
  margin-top: companyH(40vh);
  .ContentMiddle {
    display: flex;
    flex: 1;
    justify-content: space-between;
    padding: 0 companyW(12vw);
    .tMap {
      margin-top: companyH(70vh);
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 1;
      border-radius: 2%;
    }
  }
}
.right-top {
  width: companyW(444vw);
  height: companyH(465vh);
  background-image: linear-gradient(to bottom, #161225, #090b0f);
  .meter {
    display: flex;
    img {
      margin: 0 companyW(10vw);
    }
    p {
      font-family: SourceHanSansCN, SourceHanSansCN;
      font-weight: bold;
      font-size: companyW(20vw);
      color: #ffffff;
    }
  }
  // .tion {
  //   display: flex;
  //   justify-content: center;
  //   margin: companyH(50vh) 0 companyH(30vh) 0;
  //   .transaction {
  //     width: companyW(120vw);
  //     height: companyH(90vh);
  //     background-image: linear-gradient(to right, #092816, #082a16);
  //     display: flex;
  //     flex-direction: column;
  //     align-items: center;
  //     justify-content: space-around;
  //     color: #fff;
  //     border: 1px dashed #64706e;
  //     p:nth-child(1) {
  //       font-weight: 700;
  //       font-size: companyW(24vw);
  //     }
  //   }
  //   .transaction:nth-child(1) {
  //     margin-right: companyW(28vw);
  //     p:nth-child(2) {
  //       color: #1ba990;
  //       font-size: companyW(18vw);
  //     }
  //   }
  //   .transaction:nth-child(2) {
  //     p:nth-child(2) {
  //       color: #33cd5e;
  //       font-size: companyW(18vw);
  //     }
  //   }
  // }
  // .tion_botom {
  //   justify-content: space-around;
  //   .transaction:nth-child(1) {
  //     margin: 0;
  //     background-image: linear-gradient(to bottom, #0f2332, #0e1a24);
  //     p:nth-child(2) {
  //       color: #85aac6;
  //       font-size: companyW(18vw);
  //     }
  //   }
  //   .transaction:nth-child(2) {
  //     background-image: linear-gradient(to bottom, #121e34, #101826);
  //     p:nth-child(2) {
  //       color: #5486e4;
  //       font-size: companyW(18vw);
  //     }
  //   }
  //   .transaction:nth-child(3) {
  //     background-image: linear-gradient(to bottom, #251535, #181427);
  //     p:nth-child(2) {
  //       color: #4c0b7b;
  //       font-size: companyW(18vw);
  //     }
  //   }
  // }
  .dizuo {
    width: companyW(440vw);
    height: companyH(28vh);
  }
}
.right-botom {
  width: companyW(444vw);
  height: companyH(463vh);
  background-image: linear-gradient(to bottom, #161225, #090b0f);
  margin-top: companyH(22vh);
  .meter {
    display: flex;
    img {
      margin: 0 companyW(10vw);
    }
    p {
      font-family: SourceHanSansCN, SourceHanSansCN;
      font-weight: bold;
      font-size: companyW(20vw);
      color: #ffffff;
    }
  }
  .header {
    height: companyH(50vh);
    border-top: 1px solid #3e5174;
    border-bottom: 1px solid #3e5174;
    display: flex;
    color: #a5bfe1;
    align-items: center;
    p:nth-child(1) {
      margin: 0 companyW(15vw) 0 companyW(15vw);
    }
    p:nth-child(3) {
      margin: 0 companyW(40vw) 0 companyW(40vw);
    }
  }
}
.xiangxi {
  color: #e6e6e6;
  display: flex;
  // justify-content: space-between;
  font-size: companyW(18vw);
  align-items: center;
  border-bottom: 1px solid #1e293f;
  margin-bottom: companyH(10vh);
  padding-right: companyW(10vw);
  padding-left: companyW(15vw);

  .shopName,
  .count,
  .orderTime {
    width: 28%;
    overflow: hidden;
    text-overflow: ellipsis; 
    white-space: nowrap; 
    margin-left: companyW(20vw);
  }
}
.seamless-warp {
  height: companyH(380vh);
  overflow: hidden;
}
.productType {
  width: companyW(400vw);
  height: companyH(30vh);
  margin: companyH(20vh) auto;
  button {
    background: #21163c;
    color: #a0a0a2;
    width: companyW(80vw);
  }
  .check {
    background: #4b2b89;
    color: #fff;
  }
}
.maintiao {
  padding: 0 companyW(20vw);
  margin-bottom: companyW(20vw);
}
.trend {
  display: flex;
  color: #fff;
  align-items: center;
  img {
    width: companyW(22vw);
    height: companyH(22vh);
    margin: 0 companyW(30vw) 0 companyW(5vw);
  }
  p:nth-child(1) {
    font-family: SourceHanSansSC, SourceHanSansSC;
    font-weight: 400;
    font-size: companyW(16vw);
    color: #ffffff;
    margin-right: companyW(50vw);
    width: 35%;
  }
  .shangsheng,
  .xiajiang,
  .bubian {
    font-family: SourceHanSansSC, SourceHanSansSC;
    font-weight: 400;
    font-size: companyW(16vw);
    color: #39d46c;
    width: 28%;
  }
  .xiajiang {
    color: #f9882c;
  }
  .bubian {
    color: #aaaaaa;
  }
  .number {
    font-family: DIN, DIN;
    font-weight: 500;
    font-size: companyW(18vw);
    color: #79baeb;
  }
}

// 隐藏百分比单位
::v-deep .el-progress__text {
  display: none !important;
}
::v-deep .el-progress-bar {
  padding: 0 !important;
}
.decline {
  color: #f9882c;
}
.Invariant {
  color: #aaaaaa;
}
</style>
